<template>
  <div class="home">
    <div key="home-content">
      <h2 class="name">
        Bienvenue {{ userName }}&nbsp;!
      </h2>
      <p class="profil" v-if="userTypeProfileLabel">{{ userTypeProfileLabel }}</p>

      <div class="buttons-wrapper">
        <router-link v-if="showLabelRgeButton" to="/tout-savoir-rge" class="button rge-button icon-button">Tout savoir du label RGE</router-link>
        <router-link to="/questionnary" class="button icon-button session-button">Lancer une session de test</router-link>
      </div>

      <home-progress-block></home-progress-block>

      <router-link to="/revision" class="button revision-button icon-button">Réviser pour votre examen</router-link>
      <div class="links-wrapper">
        <router-link class="link" to="/comment-ca-marche">Comment ça marche&nbsp;?</router-link>
        <router-link class="link" to="/vos-donnees-et-vos-droits">Vos données et vos droits</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HomeProgressBlock from '@/components/HomeProgressBlock'

export default {
  name: 'Home',
  components: { HomeProgressBlock },
  computed: {
    ...mapState('user', ['userType', 'userName']),
    ...mapGetters(['isEnseigneWithFirstLoginForm', 'isEnseigneBretagneMateriaux']),
    userTypeProfileLabel () {
      if (this.userType === 'user') return 'Profil utilisateur enseigne'
      if (this.userType === 'examiner') return 'Profil examinateur'
      return null
    },
    showLabelRgeButton () {
      return this.isEnseigneWithFirstLoginForm || this.isEnseigneBretagneMateriaux
    }
  },
  created () {
    this.$store.dispatch('test/getTestDatas')
  }
}
</script>

<style lang="scss">
.home {
  padding: $spacer-md;
  h2 {
    font-size: 1.2rem;
    text-align: left;
    margin: 0;
    font-weight: 900;
    margin-top: 15px;
    &.name {
      margin-top: 0;
    }
  }
  p {
    &.profil {
      text-align: left;
      font-size: 0.8rem;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
  .buttons-wrapper {
    margin: 15px 0;
  }
  .icon-button {
    min-height: 56px;
    border: 1px #000 solid;
    color: #000;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    font-weight: 700;
    font-size: 0.95rem;
    background-repeat: no-repeat;
    background-position: left 25px center;
  }
  .revision-button {
    margin-top: 20px;
    background-image: url('../assets/pictos/book-dark.svg');
  }
  .rge-button {
    background-image: url('../assets/pictos/rge.svg');
  }
  .session-button {
    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
    color: #fff;
    border: none;
    background-image: url('../assets/pictos/check-white.svg');
  }
  .links-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    .link {
      color: #000;
      font-size: 0.85rem;
      font-weight: 900;
      text-decoration: underline;
    }
  }
  .grey-text-bloc {
    margin-top: 20px;
  }
  .score {
    &.fail {
      color: $error-color;
    }
    &.success {
      color: $secondary-color;
    }
  }
}
</style>
