import Vue from 'vue'
import Vuex from 'vuex'
import User from '../services/User'
import testStore from '@/store/testStore'
import userStore from '@/store/userStore'
import * as Sentry from '@sentry/vue'
import ENSEIGNE_CONST from '@/constants/ENSEIGNE_CONST'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    test: testStore,
    user: userStore
  },
  state: {
    enseigne: '',
    club: false,
    demoRegions: null,
    demoCurrentRegion: null,
    redirectSSOBaseUrl: process.env.VUE_APP_REDIRECT_SSO_BASE_URL,
    revisionsBaseUrl: 'https://files.objectif-rge.fr/',
    loadingExams: true,
    loadingExamsError: false,
    loadingExamsInfo: false,
    examDate: null,
    examDates: [],
    examReservationMessage: null,
    examReservationError: false,
    examReservationErrorMessage: null,
    demoMode: false,
    loadingErrorMessage: []
  },
  getters: {
    getEnseigneTotal (state) {
      return state.enseigne + (state.club ? 'club' : '')
    },
    canPassExam (state) {
      return state.examDate === null && state.user.userMature === true && state.user.userType === 'customer'
    },
    isEnseignePP (state) {
      return state.enseigne === ENSEIGNE_CONST.POINTP
    },
    isEnseigneCedeo (state) {
      return state.enseigne === ENSEIGNE_CONST.CEDEO
    },
    isEnseigneBretagneMateriaux (state) {
      return state.enseigne === ENSEIGNE_CONST.BRETAGNE_MATERIAUX
    },
    isEnseigneWithFirstLoginForm (state, getters) {
      return getters.isEnseignePP || getters.isEnseigneCedeo
    }
  },
  mutations: {
    setDemoCurrentRegion (state, region) {
      state.demoCurrentRegion = region
    },
    setExamReservationError (state, error) {
      state.examReservationError = error
    },
    setExamReservationErrorMessage (state, error) {
      state.examReservationErrorMessage = error
    },
    setClub (state, club) {
      state.club = club
    },
    setDemoMode (state, demoMode) {
      state.demoMode = demoMode
    },
    setEnseigne (state, enseigne) {
      state.enseigne = enseigne
    },
    setExamDate (state, date) {
      state.examDate = date
    },
    setExamDates (state, dates) {
      state.examDates = dates
    },
    setExamReservationMessage (state, message) {
      state.examReservationMessage = message
    },
    setLoadingExams (state, loading) {
      state.loadingExams = loading
    },
    setLoadingExamsError (state, error) {
      state.loadingExamsError = error
    },
    setLoadingExamsInfo (state, info) {
      state.loadingExamsInfo = info
    },
    setDemoRegions (state, regions) {
      state.demoRegions = regions
    },
    setLoadingErrorMessage (state, message) {
      state.loadingErrorMessage.push(message)
    }
  },
  actions: {
    getDatesExamens ({ state, commit }) {
      commit('setLoadingExams', true)
      commit('setLoadingExamsInfo', false)
      commit('setLoadingExamsError', false)
      if (state.demoMode) {
        User.getDemoExamenRegion(state.demoCurrentRegion)
          .then(response => {
            commit('setLoadingExams', false)
            if (response.data.length) {
              const examDates = response.data
              examDates.forEach((exam) => {
                exam.inscription = null
              })
              if (state.examDate !== null) {
                const indexExam = examDates.findIndex(exam => {
                  return exam.id === state.examDate.id
                })
                examDates[indexExam] = state.examDate
              }
              commit('setExamDates', examDates)
            } else {
              commit('setLoadingExamsInfo', 'Aucune place d\'examen n\'est actuellement disponible dans votre région.')
            }
          })
          .catch(error => {
            commit('setLoadingExams', false)
            commit('setLoadingExamsError', 'Une erreur est survenue lors de la récupération des dates d\'examen : ' + error)
          })
      } else {
        User.getDatesExamens()
          .then(response => {
            commit('setLoadingExams', false)
            if (response.data.length) {
              commit('setExamDates', response.data)
              if (state.examDates.find(examen => examen.inscription !== null)) {
                const dateExam = state.examDates.find(examen => examen.inscription !== null)
                commit('setExamDate', dateExam)
              }
            } else {
              commit('setLoadingExamsInfo', 'Aucune place d\'examen n\'est actuellement disponible dans votre région.')
            }
          })
          .catch(error => {
            commit('setLoadingExams', false)
            commit('setLoadingExamsError', 'Une erreur est survenue lors de la récupération des dates d\'examen : ' + error)
          })
      }
    },
    inscriptionExamen ({ state, commit, dispatch }, { examenId, datas }) {
      if (state.demoMode) {
        state.examDates.find(examen => examen.id === examenId).inscription = {
          date: Date.now(),
          status: 'En attente de paiement'
        }
        commit('setExamDate', state.examDates.find(examen => examen.id === examenId))
        dispatch('getDatesExamens')
        commit('setExamReservationMessage', 'Votre réservation pour l\'examen a bien été pris en compte.')
      } else {
        User.postInscriptionExamen(examenId, datas)
          .then(() => {
            commit('setExamReservationError', false)
            commit('setExamReservationErrorMessage', null)
            dispatch('getDatesExamens')
            commit('setExamReservationMessage', 'Votre réservation pour l\'examen a bien été pris en compte.')
          })
          .catch(error => {
            commit('setExamReservationError', true)
            if (error.response.data && error.response.data.message) {
              commit('setExamReservationErrorMessage', error.response.data.message)
            } else {
              commit('setExamReservationErrorMessage', 'Une erreur c\'est produite.')
            }
          })
      }
    },
    async getDemoRegions ({ state, commit }) {
      if (!state.demoMode) {
        return
      }
      try {
        const response = await User.getDemoRegionsEnseigne(state.enseigne)
        commit('setDemoRegions', response.data)
        commit('setDemoCurrentRegion', response.data[0].code)
      } catch (e) {
        Sentry.captureException(e)
        console.error(e)
      }
    },
    changeDemoCurrentRegion ({ state, commit, dispatch }, region) {
      if (!state.demoMode) {
        return
      }
      commit('setDemoCurrentRegion', region)
      dispatch('getDatesExamens')
    }
  }
})

export default store
