import { render, staticRenderFns } from "./AboutRGEFarther.vue?vue&type=template&id=5bbd38ec&scoped=true&"
import script from "./AboutRGEFarther.vue?vue&type=script&lang=js&"
export * from "./AboutRGEFarther.vue?vue&type=script&lang=js&"
import style0 from "./AboutRGEFarther.vue?vue&type=style&index=0&id=5bbd38ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbd38ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bas/app_db44f0bf-7a7f-4284-884b-635e9f59f535/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bbd38ec')) {
      api.createRecord('5bbd38ec', component.options)
    } else {
      api.reload('5bbd38ec', component.options)
    }
    module.hot.accept("./AboutRGEFarther.vue?vue&type=template&id=5bbd38ec&scoped=true&", function () {
      api.rerender('5bbd38ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/about-rge/AboutRGEFarther.vue"
export default component.exports