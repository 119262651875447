var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { key: "home-content" },
      [
        _c("h2", { staticClass: "name" }, [
          _vm._v(" Bienvenue " + _vm._s(_vm.userName) + " ! "),
        ]),
        _vm.userTypeProfileLabel
          ? _c("p", { staticClass: "profil" }, [
              _vm._v(_vm._s(_vm.userTypeProfileLabel)),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttons-wrapper" },
          [
            _vm.showLabelRgeButton
              ? _c(
                  "router-link",
                  {
                    staticClass: "button rge-button icon-button",
                    attrs: { to: "/tout-savoir-rge" },
                  },
                  [_vm._v("Tout savoir du label RGE")]
                )
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "button icon-button session-button",
                attrs: { to: "/questionnary" },
              },
              [_vm._v("Lancer une session de test")]
            ),
          ],
          1
        ),
        _c("home-progress-block"),
        _c(
          "router-link",
          {
            staticClass: "button revision-button icon-button",
            attrs: { to: "/revision" },
          },
          [_vm._v("Réviser pour votre examen")]
        ),
        _c(
          "div",
          { staticClass: "links-wrapper" },
          [
            _c(
              "router-link",
              { staticClass: "link", attrs: { to: "/comment-ca-marche" } },
              [_vm._v("Comment ça marche ?")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link",
                attrs: { to: "/vos-donnees-et-vos-droits" },
              },
              [_vm._v("Vos données et vos droits")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }