import { render, staticRenderFns } from "./Revision.vue?vue&type=template&id=2d2c36d6&"
import script from "./Revision.vue?vue&type=script&lang=js&"
export * from "./Revision.vue?vue&type=script&lang=js&"
import style0 from "./Revision.vue?vue&type=style&index=0&id=2d2c36d6&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bas/app_db44f0bf-7a7f-4284-884b-635e9f59f535/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d2c36d6')) {
      api.createRecord('2d2c36d6', component.options)
    } else {
      api.reload('2d2c36d6', component.options)
    }
    module.hot.accept("./Revision.vue?vue&type=template&id=2d2c36d6&", function () {
      api.rerender('2d2c36d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Revision.vue"
export default component.exports