var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rgpd" },
    [
      _c("h2", [_vm._v("Vos données et vos droits")]),
      _c("p", [
        _vm._v(
          "Nous attachons une grande importance au respect de votre vie privée et à la protection de vos données. Nous nous engageons ainsi à assurer le meilleur niveau de protection à vos données personnelles et à les traiter de manière loyale et transparente, conformément aux réglementations européennes et françaises applicables en matière de protection des données personnelles. Vous trouverez ainsi sur cette page l’ensemble des notices d’information relatives aux traitements de données que nous réalisons ainsi que nos politiques relatives à la gestion des cookies et Privacy."
        ),
      ]),
      _c("h2", [_vm._v("Notices d'information")]),
      _c("p", [
        _vm._v("Retrouvez ici la liste et le détail de tous nos traitements :"),
      ]),
      _c("Accordion", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " Notice d'information : Création et gestion de votre compte "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "La présente notice a pour objet de vous apporter l’ensemble des éléments d’information prévus par la règlementation applicable et, en particulier, par le Règlement européen Général sur la protection des données (« RGPD »)."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "Identité et coordonnées du responsable du traitement :"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON Représentée par : Monsieur Florent Buffin"
                  ),
                ]),
                _c("h2", [_vm._v("Objet du traitement :")]),
                _c("ul", [
                  _c("li", [_vm._v("Finalité(s) :")]),
                  _vm._v(" Ce traitement a été mis en place afin de : "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Créer un compte pour l'accès à l'entrainement à l'examen de la certification RGE"
                      ),
                    ]),
                    _c("li", [
                      _vm._v("Garantir l'unicité de l'accès à l'entrainement"),
                    ]),
                  ]),
                  _c("li", [_vm._v("Base(s) juridique(s) du traitement :")]),
                  _vm._v(
                    " Ce traitement est réalisé dans le cadre de l’exécution d’un contrat, pour vous offrir un accès complet à nos services en ligne. "
                  ),
                ]),
                _c("h2", [_vm._v("Destinataire(s) :")]),
                _c("p", [
                  _vm._v(
                    "Les informations liées à votre demande et les données personnelles permettant de vous contacter sont communiquées uniquement à nos services internes afin de vous apporter une réponse correspondant à vos besoins."
                  ),
                ]),
                _c("h2", [_vm._v("Catégorie(s) de données concernées :")]),
                _c("p", [
                  _vm._v(
                    "Pour effectuer ce traitement, les données suivantes sont utilisées :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Des données identifiantes : Nom, prénom, email, raison sociale de l’entreprise, numéro de téléphone, adresse"
                    ),
                  ]),
                ]),
                _c("h2", [_vm._v("Durée(s) de conservation des données :")]),
                _c("p", [
                  _vm._v(
                    " Nous ne conserverons vos informations personnelles que pendant la durée strictement nécessaire aux finalités énoncées précédemment ou pour satisfaire à nos obligations légales ou en vue d'exercer, de défendre ou de faire valoir nos droits. Après 3 ans d'inactivité sur votre compte vos données personnelles sont automatiquement supprimées. Un email de notification vous est envoyé à ce moment là. "
                  ),
                ]),
                _c("h2", [_vm._v("Droits des personnes :")]),
                _c("p", [
                  _vm._v(
                    "Vous pouvez exercer l’ensemble des droits suivants sur vos données personnelles :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Droit d’accès ;")]),
                  _c("li", [_vm._v("Droit de rectification ;")]),
                  _c("li", [_vm._v("Droit à l’effacement ;")]),
                  _c("li", [_vm._v("Droit à la limitation du traitement ;")]),
                  _c("li", [_vm._v("Droit d’opposition ;")]),
                  _c("li", [_vm._v("Droit à la portabilité.")]),
                ]),
                _c("p", [
                  _vm._v(
                    "Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés ou que le présent traitement de données n’est pas conforme aux règles de protection des données, vous disposez du droit d’introduire une réclamation auprès de la "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cnil.fr/fr/plaintes",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "Commission Nationale de l’Informatique et des Libertés (CNIL)"
                      ),
                    ]
                  ),
                  _vm._v(
                    ". Pour exercer ces droits ou pour toute question sur le traitement de vos données par notre société, consultez la rubrique « Comment exercer vos droits »."
                  ),
                ]),
                _c("h2", [_vm._v("Information(s) complémentaire(s)")]),
                _c("p", [
                  _vm._v(
                    "Nous vous rappelons que conformément aux dispositions de l’article 40-1 de la loi 78-17 du 6 janvier 1978, vous disposez du droit de formuler des directives générales (auprès d’un tiers de confiance numérique certifié par la CNIL) ou particulières (auprès du responsable de traitement) relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès."
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Accordion", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Notice d'information : Inscription à l'examen ")]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "La présente notice a pour objet de vous apporter l’ensemble des éléments d’information prévus par la règlementation applicable et, en particulier, par le Règlement européen Général sur la protection des données (« RGPD »)."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "Identité et coordonnées du responsable du traitement :"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON Représentée par : Monsieur Florent Buffin"
                  ),
                ]),
                _c("h2", [_vm._v("Objet du traitement :")]),
                _c("ul", [
                  _c("li", [_vm._v("Finalité(s) :")]),
                  _vm._v(" Ce traitement a été mis en place afin de : "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "S'inscrire à l'examen de la certification RGE auprès de l'APEE"
                      ),
                    ]),
                    _c("li", [
                      _vm._v("Garantir l'unicité de l'inscription à l'examen"),
                    ]),
                  ]),
                  _c("li", [_vm._v("Base(s) juridique(s) du traitement :")]),
                  _vm._v(
                    " Ce traitement est réalisé dans le cadre de l’exécution d’un contrat, pour vous offrir un accès complet à nos services en ligne. "
                  ),
                ]),
                _c("h2", [_vm._v("Destinataire(s) :")]),
                _c("p", [
                  _vm._v(
                    "Les informations liées à votre demande et les données personnelles permettant de vous contacter sont communiquées uniquement à nos services internes afin de vous apporter une réponse correspondant à vos besoins."
                  ),
                ]),
                _c("h2", [_vm._v("Catégorie(s) de données concernées :")]),
                _c("p", [
                  _vm._v(
                    "Pour effectuer ce traitement, les données suivantes sont utilisées :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Des données identifiantes : Nom, prénom, email, raison sociale de l’entreprise, numéro de téléphone, adresse"
                    ),
                  ]),
                ]),
                _c("h2", [_vm._v("Durée(s) de conservation des données :")]),
                _c("p", [
                  _vm._v(
                    "Nous ne conserverons vos informations personnelles que pendant la durée strictement nécessaire aux finalités énoncées précédemment ou pour satisfaire à nos obligations légales ou en vue d'exercer, de défendre ou de faire valoir nos droits."
                  ),
                ]),
                _c("h2", [_vm._v("Droits des personnes :")]),
                _c("p", [
                  _vm._v(
                    "Vous pouvez exercer l’ensemble des droits suivants sur vos données personnelles :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Droit d’accès ;")]),
                  _c("li", [_vm._v("Droit de rectification ;")]),
                  _c("li", [_vm._v("Droit à l’effacement ;")]),
                  _c("li", [_vm._v("Droit à la limitation du traitement ;")]),
                  _c("li", [_vm._v("Droit d’opposition ;")]),
                  _c("li", [_vm._v("Droit à la portabilité.")]),
                ]),
                _c("p", [
                  _vm._v(
                    "Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés ou que le présent traitement de données n’est pas conforme aux règles de protection des données, vous disposez du droit d’introduire une réclamation auprès de la "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cnil.fr/fr/plaintes",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "Commission Nationale de l’Informatique et des Libertés (CNIL)"
                      ),
                    ]
                  ),
                  _vm._v(
                    " et des Libertés (CNIL). Pour exercer ces droits ou pour toute question sur le traitement de vos données par notre société, consultez la rubrique « Comment exercer vos droits »."
                  ),
                ]),
                _c("h2", [_vm._v("Information(s) complémentaire(s)")]),
                _c("p", [
                  _vm._v(
                    "Nous vous rappelons que conformément aux dispositions de l’article 40-1 de la loi 78-17 du 6 janvier 1978, vous disposez du droit de formuler des directives générales (auprès d’un tiers de confiance numérique certifié par la CNIL) ou particulières (auprès du responsable de traitement) relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès."
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("h2", [_vm._v("Charte des données personnelles")]),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.saint-gobain.com/sites/saint-gobain.com/files/media/document/politique_privacy.pdf",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("Consulter la charte")]
      ),
      _c("h2", [_vm._v("Comment exercer vos droits")]),
      _vm._m(0),
      _c("p", [
        _vm._v(
          "Données personnelles PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Si vous avez des questions sur la manière dont nous traitons et utilisons vos données, ou si vous souhaitez exercer l’un quelconque de vos droits Informatique et Libertés, vous pouvez nous contacter à l’adresse suivante "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:privacycontact.pia-production.fr@saint-gobain.com",
          },
        },
        [_vm._v("privacycontact.pia-production.fr@saint-gobain.com")]
      ),
      _vm._v(" ou nous écrire à l’adresse postale suivante :"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }