<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="loadingExams" class="loading" key="loading">
        <img src="../assets/loading.gif" alt="image de chargement">
      </div>
      <div v-else-if="loadingExamsError" key="error-content">
        <p>
          {{ loadingExamsError }}
        </p>
      </div>
      <div v-else>
        <div class="examen-info" v-if="examen">
          <span class="date">
            <img src="../assets/pictos/calendar-orange.svg">
            Date d'examen : {{ examen.date | formatDate }}
          </span>
          <span class="date">
            <img src="../assets/pictos/calendar-orange.svg">
            Date limite d’inscription : {{ examen.date_limit_inscription | formatDate }}
          </span>
          <span class="location">
            <img src="../assets/pictos/location.svg">
            {{ examen.location }}
          </span>
        </div>
        <div class="important-info" v-if="examDate === null">
          <span class="title">
            Information importante
          </span>
          <p class="details">
            Merci de renseigner les informations de la personne qui va passer l'examen.<br>
            Le candidat doit faire partie de la société.
          </p>
        </div>
        <div class="important-info error" v-if="examReservationError">
          <span class="title">
            Erreur
          </span>
          <p class="details">
            {{ examReservationErrorMessage }}
          </p>
        </div>
        <p v-if="demoMode" style="margin-top: 1em; font-size: 0.8rem;">
          Environnement de démonstration : l'inscription ne sera pas prise en compte.
        </p>
        <form class="inscription-form" v-if="examDate === null">
          <div class="form" :class="{error : firstName.error}">
            <label for="firstName">Prénom*</label>
            <input type="text" name="firstName"  v-model="firstName.value" id="firstName">
            <div class="question-help" v-if="firstName.help">
              {{ firstName.help }}
            </div>
          </div>
          <div class="form" :class="{error : lastName.error}">
            <label for="lastName">Nom*</label>
            <input type="text" name="lastName"  v-model="lastName.value" id="lastName">
            <div class="question-help" v-if="lastName.help">
              {{ lastName.help }}
            </div>
          </div>
          <div class="form" :class="{error : phone.error}">
            <label for="phone">Téléphone*</label>
            <input type="tel" name="phone" v-model="phone.value" id="phone" @input="acceptNumber" pattern="/[0-9+]$/g">
            <div class="question-help" v-if="phone.help">
              {{ phone.help }}
            </div>
          </div>
          <div class="form" :class="{error : email.error}">
            <label for="email">Email*</label>
            <input type="email" name="email"  v-model="email.value" id="email">
            <div class="question-help" v-if="email.help">
              {{ email.help }}
            </div>
          </div>
          <div @click="checkForm" class="button">Réserver</div>
        </form>
        <div class="important-info validation" v-else>
          <p>Vous avez effectué une inscription pour cet examen le <strong>{{examDate.inscription.date | formatDate}}</strong>.</p>
          <p><br>Votre inscription est <strong>{{examDate.inscription.status}}</strong></p>
          <span class="payment" v-if="examDate.inscription.status === 'En attente de paiement'">
            <br>
            <p>Plus qu'une étape avant la réservation de votre examen :</p>
            <a :href="payementLink" class="button">Régler mon examen</a>
          </span>
        </div>
        <span class="info-rgpd">
          <h2>Inscription à l'examen</h2>

          <p>PIA Production SAS traite vos données pour vous incrire à l'examen de la certification RGE.</p>
          <p>Pour en savoir plus sur la manière dont nous gérons vos données à caractère personnel, veuillez consulter la rubrique <router-link class="link" to="/vos-donnees-et-vos-droits">Vos données et vos droits</router-link>.</p>
          <p>Les données personnelles renseignées dans ce formulaire seront communiquées à ATEE avec pour objectif d’assurer l’amélioration de la qualité des produits et services proposés, d’établir des statistiques commerciales et/ou de lui adresser des sollicitations commerciales de ATEE, de ses filiales et/ou de leurs partenaires commerciaux qui pourront accéder aux informations qui le concernent dans ce cadre.</p>
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ReservationExamen',
  data () {
    return {
      firstName: {
        value: '',
        help: null,
        error: false
      },
      lastName: {
        value: '',
        help: null,
        error: false
      },
      phone: {
        value: '',
        help: 'Exemple de format : 0675845642 ou +33645789585',
        error: false
      },
      email: {
        value: '',
        help: null,
        error: false
      },
      errorMessage: {
        empty: 'Veuillez remplir ce champs.',
        mail: '',
        error: false
      },
      urlPayement: {
        cedeo: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/79-special-cedeo-qcm-efficacite-energetique-candidat-libre.html',
        cedeoclub: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/80-special-cedeo-boost-qcm-efficacite-energetique-candidat-libre.html',
        pointp: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/81-special-point-p-qcm-efficacite-energetique-candidat-libre.html',
        pointpclub: 'https://e-boutique.apee.fr/accueil/78-special-generation-artisans-qcm-efficacite-energetique-candidat-libre.html',
        pdb: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/82-special-plateforme-du-batiment-qcm-efficacite-energetique-candidat-libre.html',
        asturienne: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/90-special-asturienne-qcm-efficacite-energetique-candidat-libre.html',
        dispano: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/91-special-dispano-qcm-efficacite-energetique-candidat-libre.html',
        sfic: '',
        sficclub: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/89-special-sfic-qcm-efficacite-energetique-candidat-libre.html',
        pum: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/83-special-pum-plastisques-qcm-efficacite-energetique-candidat-libre.html',
        lemanmateriaux: 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/84-special-lehmann-materiaux-qcm-efficacite-energetique-candidat-libre.html',
        'cba-som': 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/86-special-cba-qcm-efficacite-energetique-candidat-libre.html',
        'bretagne-materiaux': 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/87-special-bretagne-materiaux-qcm-efficacite-energetique-candidat-libre.html',
        'bernard-philibert': 'https://e-boutique.apee.fr/examen-qcm-efficacite-energetique/88-special-bernard-philibert-qcm-efficacite-energetique-candidat-libre.html'
      }
    }
  },
  computed: {
    ...mapState(['examReservationMessage', 'examDate', 'enseigne', 'loadingExams', 'loadingExamsError', 'examReservationError', 'examReservationErrorMessage', 'demoMode']),
    ...mapGetters(['getEnseigneTotal']),
    examen () {
      return this.$store.state.examDates.find(examen => examen.id === Number(this.examenId))
    },
    examenId () {
      return this.$route.params.examenId
    },
    formIsValid () {
      return this.firstName.error === false &&
      this.lastName.error === false &&
      this.phone.error === false &&
      this.email.error === false
    },
    payementLink () {
      if (this.getEnseigneTotal) {
        return this.urlPayement[this.getEnseigneTotal]
      }
      return this.urlPayement.cedeo
    }
  },
  methods: {
    checkForm () {
      this.resetHelp()
      const forms = [
        this.firstName,
        this.lastName,
        this.phone,
        this.email
      ]
      this.checkEmail()
      this.checkPhone()
      forms.forEach(form => {
        if (!this.checkLength(form.value)) {
          form.help = 'Veuillez remplir ce champs.'
          form.error = true
        }
      })
      if (this.formIsValid) {
        this.$store.dispatch('inscriptionExamen', {
          examenId: this.examenId,
          datas: {
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            phone: this.phone.value,
            email: this.email.value
          }
        })
      }
    },
    acceptNumber () {
      this.phone.value = this.phone.value.replace(/[^0-9+]$/g, '')
    },
    checkEmail () {
      const regexMail = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
      if (!regexMail.test(this.email.value)) {
        this.email.error = true
        this.email.help = 'La valeur ' + this.email.value + ' n\'est pas un email valide.'
      }
    },
    checkPhone () {
      const regexPhone = RegExp(/^(\+?33|0)(\s?\d){9}$/)
      if (!regexPhone.test(this.phone.value)) {
        this.phone.error = true
        this.phone.help = 'La valeur ' + this.phone.value + ' n\'est pas un numéro de téléphone valide.'
      }
    },
    checkLength (valeur) {
      return valeur.length > 0
    },
    resetHelp () {
      this.firstName.help = null
      this.firstName.error = false

      this.lastName.help = null
      this.lastName.error = false

      this.phone.help = 'Exemple de format : 0675845642 ou +33645789585'
      this.phone.error = false

      this.email.help = null
      this.email.error = false
    }
  }
}
</script>

<style lang="scss">
.info-rgpd {
  padding: 20px;
  display: block;
  font-size: 0.8em;
  text-align: left;
  p {
    margin: 10px 0;
  }
  h2 {
    font-size: 1.3em;
  }
  .link {
    text-decoration: none;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    transition: all .2s ease;
    &:hover {
      text-decoration: underline;
      @include themify($themes) {
        color: darken(themed('backgroundColor'), 20%);
      }
    }
  }
}
.examen-info {
  margin: $spacer-md;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .location {
    height: 18px;
    padding: 8px 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    img {
      margin-left: 2px;
      width: 30px;
    }
  }
  .date {
    padding: 8px;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
}

.important-info {
  color: #fff;
  @include themify($themes) {
    color: themed('backgroundColor');
  }
  padding: $spacer-lg;
  border-radius: $radius;
  @include themify($themes) {
    border-color: themed('backgroundColor');
  }
  border: 1px solid;
  &.error {
    margin-top: 1em;
    color: $error-color;
    border-color: $error-color;
  }
  .title {
    text-align: center;
    font-weight: 900;
    font-size: 1.2rem;
    margin-bottom: $spacer-md;
  }
  .details {
    margin-top: $spacer-md;
  }
  &.validation {
    margin-top: 50px;
  }
  .payment {
    display: flex;
    flex-direction: column;
    .button {
      display: block;
      width: max-content;
      align-self: center;
      margin-top: $spacer-md;
      padding: $spacer $spacer;
      font-weight: 900;
      color: #fff;
      @include themify($themes) {
        background-color: themed('backgroundColor');
      }
      transition: 0.3s background-color;
    }
  }
}

.inscription-form {
  box-sizing: border-box;
  padding: 0 10px;
  margin: 30px auto 0;
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  .form {
    &.error {
      label {
        color: $error-color;
      }
      input {
        border: 1px solid $error-color;
      }
      .question-help {
        color: $error-color;
      }
    }
    text-align: left;
    margin-bottom: 5px;
    label {
      display: block;
      padding: 5px 0;
    }
    input {
      padding: 2px 10px;
      border-radius: $radius;
      border: 1px solid $border-color;
      width: 100%;
      box-sizing: border-box;
      max-width: 430px;
      height: 2em;
      margin-bottom: 2px;
    }
    .question-help {
      color: #707070;
      text-align: left;
      font-size: 0.8em;
      width: 100%;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $spacer-md;
    padding: $spacer $spacer;
    font-weight: 900;
    color: #fff;
    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
    transition: 0.3s background-color;
  }
}

</style>
